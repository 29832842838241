import { useState } from 'react';
import useSWR from 'swr';

import {
  type DtoSlackUser,
  EnumsExConnectType,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService, type InvitedUser } from '../../../services/api-service';
import { Loading } from '../../Loading';
import { useMyOrgConnection } from '../../Organization/hooks/organization';
import {
  EmailInviteInput,
  type InviteOption,
} from '../../Organization/OrganizerEmailInvite';
import {
  ImportSlackUsersInput,
  loadNewSlackUsers,
  NewSlackUsersBanner,
} from '../../Organization/OrganizerImporterSlack';
import {
  OnboardingTaskDetailLayout,
  type OnboardingTaskDetailSharedProps,
  OnboardingTaskSkipButton,
} from './Shared';

async function loadDataAddTeamWithSlack(orgId: string) {
  const [connection, newUsers] = await Promise.all([
    (await apiService.organization.getOrgConnection(orgId)).data.connection,
    loadNewSlackUsers(orgId),
  ]);
  return { connection, newUsers };
}

function OnboardingTaskAddTeamWithSlack(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  const { organization, markAsDone, onNext } = props;

  const { data, isLoading } = useSWR(
    ['/onboarding/tasks/add-members-with-slack', organization.id],
    () => loadDataAddTeamWithSlack(organization.id)
  );
  const [users, setUsers] = useState<DtoSlackUser[]>([]);

  const {
    call: handleImport,
    state: {
      state: { isRunning: isImporting },
    },
  } = useLiveAsyncCall(async () => {
    await apiService.organization.inviteOrganizers(organization.id, {
      invitedUsers: users.map((u) => ({
        email: u.email,
        fullName: u.fullName,
        exUserId: u.id,
      })),
      webEndpoint: window.location.origin,
      sendEmail: false,
    });
    await markAsDone();
    await onNext();
  });

  if (isLoading || !data) return <Loading />;
  const { connection, newUsers } = data;

  const handleAddAllNewUsers = () => {
    setUsers((prev) => {
      const updated = [...prev];
      for (const u of newUsers) {
        if (updated.find((p) => p.id === u.id)) continue;
        updated.push(u);
      }
      return updated;
    });
  };

  const qualifiedAsComplete = users.length + organization.organizersCount >= 10;
  const importTeamButtonEnabled =
    users.length > 0 && qualifiedAsComplete && !isImporting;
  const showImportAndSkip = users.length > 0 && !qualifiedAsComplete;
  const importAndSkipEnabled = showImportAndSkip && !isImporting;

  return (
    <OnboardingTaskDetailLayout
      title='Import Team'
      description={
        <>
          Share access to Luna Park with your teammates.
          <br />
          The more you invite, the more fun Luna Park gets!
        </>
      }
    >
      <main className='mt-7.5 w-full'>
        {newUsers && newUsers.length > 0 && (
          <div className='w-full mb-7.5'>
            <NewSlackUsersBanner
              connection={connection}
              newUsersCount={newUsers?.length ?? 0}
              onAddAll={handleAddAllNewUsers}
            />
          </div>
        )}

        <ImportSlackUsersInput
          organization={props.organization}
          users={users}
          onChange={setUsers}
          autofocus
        />

        <div className='mt-4 w-full bg-[#8C6FFF] rounded-xl bg-opacity-40 text-sms p-2.5'>
          🤐 Don’t worry, we won’t message or email anyone during this step!
        </div>
      </main>

      <footer className='mt-12.5 flex flex-col items-center'>
        <button
          type='button'
          disabled={!importTeamButtonEnabled}
          onClick={() => handleImport()}
          className='btn-primary w-100 h-15 flex justify-center items-center'
        >
          {isImporting && !showImportAndSkip && (
            <Loading containerClassName='mr-2' text='' imgClassName='w-6 h-6' />
          )}
          Import Team
        </button>

        {showImportAndSkip ? (
          <button
            type='button'
            onClick={() => handleImport()}
            className='mt-4 btn text-base text-icon-gray underline flex justify-center items-center'
            disabled={!importAndSkipEnabled}
          >
            {isImporting && (
              <Loading
                containerClassName='mr-2'
                text=''
                imgClassName='w-6 h-6'
              />
            )}
            Import {users.length} now, add the rest later
          </button>
        ) : (
          <OnboardingTaskSkipButton className='mt-4' {...props} />
        )}
      </footer>
    </OnboardingTaskDetailLayout>
  );
}

function OnboardingTaskAddTeamWithoutSlack(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  const { organization, markAsDone, onNext } = props;

  const [options, setOptions] = useState<InviteOption[]>([]);

  const {
    call: handleImport,
    state: {
      state: { isRunning: isImporting },
    },
  } = useLiveAsyncCall(async () => {
    const invitedUsers = options.map<InvitedUser>((v) => ({
      email: v.value,
    }));

    await apiService.organization.inviteOrganizers(organization.id, {
      invitedUsers,
      webEndpoint: window.location.origin,
      sendEmail: false,
    });

    await markAsDone();
    await onNext();
  });

  const validOptions = options.filter((o) => o.failedReason === 'success');
  const qualifiedAsComplete =
    validOptions.length + organization.organizersCount >= 10;
  const importTeamButtonEnabled =
    validOptions.length > 0 &&
    validOptions.length === options.length &&
    qualifiedAsComplete &&
    !isImporting;
  const showImportAndSkip = validOptions.length > 0 && !qualifiedAsComplete;
  const importAndSkipEnabled =
    showImportAndSkip && validOptions.length === options.length && !isImporting;

  return (
    <OnboardingTaskDetailLayout
      title='Import Team'
      description={
        <>
          Share access to Luna Park with your teammates.
          <br />
          The more you invite, the more fun Luna Park gets!
        </>
      }
    >
      <label className='mt-7.5 w-full flex flex-col items-start gap-2.5'>
        <h3 className='text-base font-bold'>Email Address</h3>
        <p className='text-base font-normal text-icon-gray'>
          Separate emails by comma, space or Enter/Return.
        </p>
        <EmailInviteInput
          organization={organization}
          options={options}
          onChange={setOptions}
          autoFocus
          isDisabled={isImporting}
        />
      </label>

      <div className='mt-13 w-full bg-[#8C6FFF] rounded-xl bg-opacity-40 text-sms p-2.5'>
        🤐 Don’t worry, we won’t message or email anyone during this step!
      </div>

      <button
        type='button'
        disabled={!importTeamButtonEnabled}
        onClick={() => handleImport()}
        className='mt-15 btn-primary w-100 h-15 flex justify-center items-center'
      >
        {isImporting && !showImportAndSkip && (
          <Loading containerClassName='mr-2' text='' imgClassName='w-6 h-6' />
        )}
        Import Team
      </button>

      {showImportAndSkip ? (
        <button
          type='button'
          onClick={() => handleImport()}
          className='mt-4 btn text-base text-icon-gray underline flex justify-center items-center'
          disabled={!importAndSkipEnabled}
        >
          {isImporting && (
            <Loading containerClassName='mr-2' text='' imgClassName='w-6 h-6' />
          )}
          Import {validOptions.length} now, add the rest later
        </button>
      ) : (
        <OnboardingTaskSkipButton {...props} className='mt-4' />
      )}
    </OnboardingTaskDetailLayout>
  );
}

export function OnboardingTaskAddTeam(props: OnboardingTaskDetailSharedProps) {
  const connection = useMyOrgConnection();
  const slackInstalled =
    !!connection &&
    connection.type === EnumsExConnectType.ExConnectTypeSlack &&
    connection.status === 'active';
  if (!slackInstalled) return <OnboardingTaskAddTeamWithoutSlack {...props} />;
  return <OnboardingTaskAddTeamWithSlack {...props} />;
}
